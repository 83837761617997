import {
    ClinicFieldsFragment,
    FilterOp,
    OrderByInput,
    QueryInput,
} from '~graphql/generated/graphql'

export function getClinicQueryInput(clinicId: string): QueryInput {
    return {
        column: 'clinic',
        filterOp: FilterOp.Eq,
        value: {
            string: clinicId,
        },
    }
}

export function getDataSourceQueryInput(
    clinic: Pick<ClinicFieldsFragment, 'id' | 'pms_db'> | null | undefined,
) {
    return clinic?.pms_db
        ? constructStringEq('pms_db', clinic.pms_db.id)
        : getClinicQueryInput(clinic?.id || '')
}

export function constructILike(column: string, query: string): QueryInput {
    return {
        column,
        filterOp: FilterOp.Ilike,
        value: {
            string: `%${query}%`,
        },
    }
}

export function constructStringEq(column: string, value: string): QueryInput {
    return {
        column,
        filterOp: FilterOp.Eq,
        value: {
            string: value,
        },
    }
}

export function constructBooleanEq(column: string, value: boolean): QueryInput {
    return {
        column,
        filterOp: FilterOp.Eq,
        value: {
            boolean: value,
        },
    }
}

export function constructStringIn(
    column: string,
    values: string[],
): QueryInput {
    return {
        column,
        filterOp: FilterOp.In,
        value: {
            stringArray: values,
        },
    }
}

export function constructOrderBy(
    column: string,
    isAscending: boolean = false,
): OrderByInput {
    return {
        column,
        isAscending,
    }
}

export function constructIsNull(column: string, not?: boolean): QueryInput {
    return {
        column,
        filterOp: FilterOp.Is,
        value: {
            string: 'null',
        },
        not,
    }
}
