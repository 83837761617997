import { getUrlQueryParamsObj } from '~utils/helpers'
import { UrlQueryParamInput } from './get-cleaned-query-params'

function preserveOtherQueryParams(other: {
    [x: string]: string | (string | null)[] | null
}): string {
    return Object.entries(other)
        .map(([key, value]) => {
            if (Array.isArray(value)) {
                return value.map(v => `${key}=${v}`).join('&')
            } else {
                return `${key}=${value}`
            }
        })
        .join('&')
}

export function constructQueryString(input: UrlQueryParamInput): string {
    const {
        newSearch,
        newClient,
        newPatient,
        newAppointment,
        newPage,
        newPageSize,
        newNotificationTemplateIds,
    } = input
    const {
        search: parsedSearch,
        client: parsedClient,
        patient: parsedPatient,
        appointment: parsedAppointment,
        page: parsedPage,
        pageSize: parsedPageSize,
        notificationTemplateIds: parsedNotificationTemplateIds,
        ...other
    } = getUrlQueryParamsObj()

    const search = typeof parsedSearch === 'string' ? parsedSearch : undefined
    const client = typeof parsedClient === 'string' ? parsedClient : undefined
    const patient =
        typeof parsedPatient === 'string' ? parsedPatient : undefined
    const appointment =
        typeof parsedAppointment === 'string' ? parsedAppointment : undefined
    const page =
        typeof parsedPage === 'string' ? parseInt(parsedPage) : undefined
    const pageSize =
        typeof parsedPageSize === 'string'
            ? parseInt(parsedPageSize)
            : undefined
    const notificationTemplateIds =
        typeof parsedNotificationTemplateIds === 'string'
            ? parsedNotificationTemplateIds.split(',')
            : undefined

    const others = preserveOtherQueryParams(other)

    let queryString = others !== '' ? `${others}&` : ''

    if (newSearch) {
        queryString += newSearch !== 'erase' ? `search=${newSearch}&` : ''
    } else if (search) {
        queryString += `search=${search}&`
    }

    if (newClient) {
        queryString += newClient !== 'erase' ? `client=${newClient}&` : ''
    } else if (client) {
        queryString += `client=${client}&`
    }

    if (newPatient) {
        queryString += newPatient !== 'erase' ? `patient=${newPatient}&` : ''
    } else if (patient) {
        queryString += `patient=${patient}&`
    }

    if (newAppointment) {
        queryString +=
            newAppointment !== 'erase' ? `appointment=${newAppointment}&` : ''
    } else if (appointment) {
        queryString += `appointment=${appointment}&`
    }

    if ((newPage || newPage === 0) && !newPageSize) {
        queryString += newPage === -1 ? '' : `page=${newPage}&`
    } else if (page && !newPageSize) {
        queryString += `page=${page}&`
    }

    if (newPageSize) {
        queryString += `pageSize=${newPageSize}&`
    } else if (pageSize) {
        queryString += `pageSize=${pageSize}&`
    }

    if (newNotificationTemplateIds) {
        queryString += `notificationTemplateIds=${newNotificationTemplateIds.join(',')}&`
    } else if (notificationTemplateIds) {
        queryString += `notificationTemplateIds=${notificationTemplateIds.join(',')}&`
    }

    if (queryString.length > 0) {
        return '?' + queryString.slice(0, -1)
    }

    return ''
}
