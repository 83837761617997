import React from 'react'
import { navigate } from 'gatsby'
import AppointmentDetailsDrawer from '../modals/appointment-details/appointment-details-drawer'
import {
    AppointmentFieldsFragment,
    ClientFieldsFragment,
    PatientFieldsFragment,
    useGetAppointmentQuery,
    useGetClientQuery,
    useGetPatientQuery,
} from '~graphql/generated/graphql'
import PatientDetailsDrawer from '../modals/patient-details/patient-drawer'
import ClientDetailsDrawer from '../modals/client-details/client-drawer'
import { getCleanedQueryParams } from '~utils/get-cleaned-query-params'
import { constructQueryString } from '~utils/construct-query-string'

export default function DetailDrawers() {
    const {
        client: clientId,
        patient: patientId,
        appointment: appointmentId,
    } = getCleanedQueryParams()

    const { data: clientData, loading: clientLoading } = useGetClientQuery({
        variables: { id: clientId || '' },
        skip: !clientId,
    })

    const { data: patientData, loading: patientLoading } = useGetPatientQuery({
        variables: { id: patientId || '' },
        skip: !patientId,
    })

    const { data: appointmentData, loading: appointmentLoading } =
        useGetAppointmentQuery({
            variables: { id: appointmentId || '' },
            skip: !appointmentId,
        })

    const client = clientData?.client as ClientFieldsFragment
    const patient = patientData?.patient as PatientFieldsFragment
    const appointment =
        appointmentData?.appointment as AppointmentFieldsFragment

    function handleCloseClient() {
        const queryString = constructQueryString({
            newClient: 'erase',
        })

        navigate(queryString, { replace: true })
    }

    function handleClosePatient() {
        const queryString = constructQueryString({
            newPatient: 'erase',
        })

        navigate(queryString, { replace: true })
    }

    function handleCloseAppointment() {
        const queryString = constructQueryString({
            newAppointment: 'erase',
        })

        navigate(queryString, { replace: true })
    }

    return (
        <>
            <ClientDetailsDrawer
                client={client}
                loading={clientLoading}
                isOpen={!!client}
                onClose={handleCloseClient}
                blockScrollOnMount={!patient && !appointment}
            />
            <PatientDetailsDrawer
                patient={patient}
                loading={patientLoading}
                isOpen={!!patient}
                onClose={handleClosePatient}
                blockScrollOnMount={!appointment && !client}
                overlayOverrides={{
                    bg: client ? 'transparent' : 'blackAlpha.600',
                }}
            />
            <AppointmentDetailsDrawer
                appointment={appointment}
                isOpen={!!appointment}
                onClose={handleCloseAppointment}
                blockScrollOnMount={!client && !patient}
                overlayOverride={{
                    bg:
                        !!client || !!patient
                            ? 'transparent'
                            : 'blackAlpha.600',
                }}
            />
        </>
    )
}
