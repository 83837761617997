import React from 'react'
import { Image } from '@chakra-ui/image'
import { Box, Text, Link, AspectRatio } from '@chakra-ui/layout'

import { useUserContext } from '~config/user-context'
import SharedStack from '~components/shared/wrappers/shared-stack'
import {
    ClinicFieldsFragment,
    ClinicGroupFieldsFragment,
    OrganizationFieldsFragment,
} from '~graphql/generated/graphql'
import MenuItem from '~components/shared/menu/menu-item'
import { BiCopy, BiLinkExternal, BiRefresh } from 'react-icons/bi'
import { navigate } from 'gatsby'
import { UserRoutes } from '~config/routes'
import { MenuGroup } from '@chakra-ui/react'
import { getSupabaseLink, SupabaseEntities } from '~config/constants'

interface Props {
    clinicGroup: ClinicGroupFieldsFragment
    setClinicGroup: (e: ClinicGroupFieldsFragment | undefined) => void
    setClinic: (e: ClinicFieldsFragment | undefined) => void
    canSwitch?: boolean
}

export default function ClinicGroupOptions({
    clinicGroup,
    setClinicGroup,
    setClinic,
    canSwitch = true,
}: Props) {
    const { isAdmin } = useUserContext()

    return (
        <>
            <SharedStack row fit px={3} spacing={4} mb={2}>
                <AspectRatio
                    ratio={1}
                    w={12}
                    borderWidth={1}
                    rounded="md"
                    overflow="hidden"
                    borderColor="gray.200"
                    bg="brand.500"
                >
                    <Image src={''} alt={clinicGroup.name || ''} />
                </AspectRatio>
                <Box>
                    <Text fontSize="md" fontWeight="semibold">
                        {clinicGroup.name}
                    </Text>
                    {isAdmin && (
                        <Text fontSize="xs" color="gray.500">
                            {clinicGroup.id}
                        </Text>
                    )}
                </Box>
            </SharedStack>
            {(canSwitch || isAdmin) && (
                <MenuItem
                    icon={BiRefresh}
                    onClick={() => {
                        setClinicGroup(undefined)
                        setClinic(undefined)
                        navigate(UserRoutes.SelectClinic)
                    }}
                >
                    Switch Clinic Group
                </MenuItem>
            )}
            {isAdmin && (
                <MenuGroup title="Admin" fontSize="xs">
                    <MenuItem
                        icon={BiCopy}
                        onClick={() =>
                            navigator.clipboard.writeText(clinicGroup.id)
                        }
                    >
                        Copy Clinic Group ID
                    </MenuItem>
                </MenuGroup>
            )}
        </>
    )
}
