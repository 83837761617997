import React from 'react'
import { navigate } from 'gatsby'
import { Box, Text } from '@chakra-ui/layout'
import { BiExit, BiUserCircle } from 'react-icons/bi'
import { Avatar, AvatarBadge } from '@chakra-ui/avatar'

import { UserRoutes } from '~config/routes'
import { useUserContext } from '~config/user-context'
import MenuItem from '~components/shared/menu/menu-item'
import {
    ClinicFieldsFragment,
    ClinicGroupFieldsFragment,
    UserFieldsFragment,
} from '~graphql/generated/graphql'
import SharedStack from '~components/shared/wrappers/shared-stack'
import UrgentIcon from '~components/shared/icons/urgent-icon'

interface Props {
    user: UserFieldsFragment
    setClinic: (e: ClinicFieldsFragment | undefined) => void
    setClinicGroup: (e: ClinicGroupFieldsFragment | undefined) => void
}

export default function UserOptions({
    user,
    setClinic,
    setClinicGroup,
}: Props) {
    const { signOut, isEmailVerified } = useUserContext()

    return (
        <>
            <SharedStack row fit px={3} spacing={4} mb={2}>
                <Avatar
                    size="sm"
                    src={user.avatar_url || ''}
                    name={user.first_name + ' ' + user.last_name}
                >
                    {!isEmailVerified && (
                        <AvatarBadge
                            borderColor="pink.100"
                            bg="pink.500"
                            boxSize="1.25em"
                        />
                    )}
                </Avatar>
                <Box>
                    <Text fontSize="sm" fontWeight="semibold">
                        {user.first_name} {user.last_name}
                    </Text>
                    <Text fontSize="xs" color="gray.500">
                        {user.email}
                    </Text>
                </Box>
            </SharedStack>
            <MenuItem
                icon={BiUserCircle}
                onClick={() => navigate(UserRoutes.Profile)}
                justifyContent={'space-between'}
            >
                <Text>Profile</Text>
                {!isEmailVerified && <UrgentIcon />}
            </MenuItem>
            <MenuItem
                icon={BiExit}
                color="red.500"
                onClick={() => {
                    setClinic(undefined)
                    setClinicGroup(undefined)
                    signOut()
                }}
            >
                Sign Out
            </MenuItem>
        </>
    )
}
