import React, {
    createContext,
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    useContext,
    useState,
} from 'react'
import {
    ApolloQueryResult,
    InternalRefetchQueriesInclude,
} from '@apollo/client'

import { useClinic } from '~contexts/clinic-context'
import {
    FilterOp,
    OrderByInput,
    QueryInput,
    QueryReportTemplatesDocument,
    QueryReportTemplatesQuery,
    ReportTemplateFieldsFragment,
    useQueryReportTemplatesQuery,
} from '~graphql/generated/graphql'

import { useDebounce } from '~utils/hooks/use-debounce'
import { eqStringFilter, eqBoolFilter } from '~utils/query-helpers'
import { useBoolean } from '@chakra-ui/react'

const ReportTemplateContext = createContext<ReportTemplatesProviderType>(
    {} as ReportTemplatesProviderType,
)

export function ReportTemplatesProvider({ children }: PropsWithChildren) {
    const { clinicGroup } = useClinic()
    const [searchString, setSearchString] = useState('')
    const [showArchived, setShowArchived] = useBoolean()

    const debouncedSearchString = useDebounce(searchString, 300)

    const filters: QueryInput[] = [
        eqStringFilter('clinic_group', clinicGroup?.id),
        ...(!showArchived ? [eqBoolFilter('is_active', true)] : []),
        {
            column: 'name',
            filterOp: FilterOp.Ilike,
            value: {
                string: `%${debouncedSearchString}%`,
            },
        },
    ]

    const orderBy: OrderByInput = {
        column: 'created_at',
        isAscending: false,
        foreignTable: 'organization_reports',
    }

    const { data, loading, error, refetch } = useQueryReportTemplatesQuery({
        variables: {
            where: filters,
            orderBy,
        },
        fetchPolicy: 'cache-and-network',
        skip: !clinicGroup,
    })

    const reportTemplates = data?.queryReportTemplates || []

    return (
        <ReportTemplateContext.Provider
            value={{
                searchString,
                setSearchString,
                showArchived,
                toggleArchived: setShowArchived.toggle,
                reportTemplates,
                refetchTemplates: refetch,
                isLoading: loading,
                refetchQueries: [
                    {
                        query: QueryReportTemplatesDocument,
                        variables: {
                            where: filters,
                            orderBy,
                        },
                    },
                ],
            }}
        >
            {children}
        </ReportTemplateContext.Provider>
    )
}

type ReportTemplatesProviderType = {
    searchString: string
    setSearchString: Dispatch<SetStateAction<string>>
    showArchived: boolean
    toggleArchived: () => void
    reportTemplates: ReportTemplateFieldsFragment[]
    refetchTemplates: () => Promise<
        ApolloQueryResult<QueryReportTemplatesQuery>
    >
    refetchQueries: InternalRefetchQueriesInclude
    isLoading: boolean
}

export function useReportTemplates(): ReportTemplatesProviderType {
    return useContext(ReportTemplateContext)
}
