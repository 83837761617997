import React, { useContext } from 'react'
import {
    Box,
    Flex,
    Icon,
    Button,
    Spinner,
    Progress,
    IconButton,
    useBreakpointValue,
} from '@chakra-ui/react'
import { Link, navigate } from 'gatsby'
import { FiMenu } from 'react-icons/fi'
import { FaGamepad } from 'react-icons/fa'
import { FaHeadset } from 'react-icons/fa6'

import { UserRoutes } from '~config/routes'
import AccountButton from './account-button'
import { UserContext } from '~config/user-context'
import { AuthRoles } from '~graphql/generated/graphql'
import { useDemoMode } from '~utils/hooks/use-demo-mode'
import { LoadingContext } from '~config/loading-context'
import ActionButton from '~components/shared/buttons/action-button'
import DemoModeButton from './demo-mode-button'

const Header: React.FC<{
    isOpen: boolean
    onOpenSidebar: () => void
    submenu: string
}> = ({ isOpen, onOpenSidebar, submenu }) => {
    const { loading } = useContext(LoadingContext)
    const { user, isLoading } = useContext(UserContext)

    const showHamburger = useBreakpointValue({ base: true, lg: false })

    return (
        <Box
            position="fixed"
            right={0}
            top={0}
            zIndex={10}
            w="100%"
            transition="all 0.4s"
            maxW={{
                base: '100%',
                lg: isOpen
                    ? 'calc(100% - 276px)'
                    : submenu !== 'none'
                      ? 'calc(100% - 128px)'
                      : 'calc(100% - 64px)',
            }}
            boxSizing="border-box"
            bg="white"
        >
            <Flex
                position="relative"
                justify="flex-end"
                align="center"
                w="100%"
                py={2}
                px={6}
            >
                <DemoModeButton
                    isSuperAdmin={user?.role === AuthRoles.SuperAdmin}
                />
                <ActionButton
                    icon={<Icon as={FaHeadset} />}
                    tooltipLabel="Contact Support"
                    onClick={() => {
                        navigate(UserRoutes.Support)
                    }}
                    variant="outline"
                    rounded="full"
                    size="sm"
                    mr={4}
                />
                {showHamburger ? (
                    <>
                        <IconButton
                            icon={<FiMenu />}
                            bg="gray.900"
                            rounded="full"
                            color="brand.400"
                            aria-label={'Menu Button'}
                            onClick={onOpenSidebar}
                        />
                    </>
                ) : (
                    <>
                        {isLoading ? (
                            <Spinner />
                        ) : user ? (
                            <AccountButton />
                        ) : (
                            <Link to={UserRoutes.Auth}>
                                <Button>Sign in</Button>
                            </Link>
                        )}
                    </>
                )}
            </Flex>
            <Progress
                h="1px"
                colorScheme="brand"
                size="sm"
                bg="gray.200"
                isIndeterminate={loading}
            />
        </Box>
    )
}

export default Header
