import React from 'react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { Divider, Flex, Text } from '@chakra-ui/layout'
import { Menu, MenuButton, MenuList } from '@chakra-ui/menu'

import UserOptions from './user-options'
import ClinicOptions from './clinic-options'
import { useClinic } from '~contexts/clinic-context'
import { useUserContext } from '~config/user-context'
import ClinicGroupOptions from './clinic-group-options'
import SharedStack from '~components/shared/wrappers/shared-stack'
import UrgentIcon from '~components/shared/icons/urgent-icon'

export default function AccountButton() {
    const { user, isEmailVerified } = useUserContext()
    const {
        clinic,
        setClinic,
        hasMultipleClinics,
        clinicGroup,
        setClinicGroup,
        isClinicAdmin,
    } = useClinic()

    return (
        <Menu placement="bottom-end">
            <MenuButton role="group">
                <SharedStack row fit rounded="md" px={2} cursor="pointer">
                    <Flex flexDir="column" align="flex-end">
                        <Text
                            fontSize="sm"
                            fontWeight="semibold"
                            color={
                                clinic || clinicGroup ? 'gray.900' : 'gray.700'
                            }
                            fontStyle={
                                clinic || clinicGroup ? 'none' : 'italic'
                            }
                        >
                            {clinic?.name ||
                                clinicGroup?.name ||
                                'Select a Clinic'}
                        </Text>
                        <SharedStack row fit spacing={2}>
                            {!isEmailVerified && <UrgentIcon fontSize="xs" />}
                            <Text
                                fontSize="xs"
                                color="gray.500"
                                transition="color 0.1s ease-out"
                                _groupHover={{
                                    color: 'gray.900',
                                }}
                            >
                                {user?.first_name + ' ' + user?.last_name}
                            </Text>
                        </SharedStack>
                    </Flex>
                    <ChevronDownIcon color="gray.900" />
                </SharedStack>
            </MenuButton>
            <MenuList px={0} w="356px">
                {clinic && (
                    <ClinicOptions
                        clinic={clinic}
                        setClinic={setClinic}
                        setClinicGroup={setClinicGroup}
                        isClinicAdmin={isClinicAdmin}
                        canSwitch={hasMultipleClinics}
                    />
                )}
                {clinicGroup && (
                    <ClinicGroupOptions
                        clinicGroup={clinicGroup}
                        setClinicGroup={setClinicGroup}
                        setClinic={setClinic}
                        canSwitch={hasMultipleClinics}
                    />
                )}
                {(clinic || clinicGroup) && <Divider my={2} />}
                {user && (
                    <UserOptions
                        user={user}
                        setClinic={setClinic}
                        setClinicGroup={setClinicGroup}
                    />
                )}
            </MenuList>
        </Menu>
    )
}
