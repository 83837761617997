import React from 'react'
import { Icon } from '@chakra-ui/icons'
import { Button } from '@chakra-ui/button'
import { FaGamepad } from 'react-icons/fa6'
import { useDemoMode } from '~utils/hooks/use-demo-mode'

interface Props {
    isSuperAdmin?: boolean
}

export default function DemoModeButton({ isSuperAdmin }: Props) {
    const { isDemoMode, toggleDemoMode } = useDemoMode()

    if (!isSuperAdmin) return null

    return (
        <Button
            size="xs"
            onClick={() => {
                toggleDemoMode()
            }}
            colorScheme={isDemoMode ? 'orange' : 'gray'}
            mr={4}
        >
            {isDemoMode ? (
                <>
                    <Icon as={FaGamepad} mr={1} /> Turn OFF Demo Mode
                </>
            ) : (
                <>
                    <Icon as={FaGamepad} mr={1} /> Turn ON Demo Mode
                </>
            )}
        </Button>
    )
}
