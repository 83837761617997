import React from 'react'
import { Avatar } from '@chakra-ui/avatar'
import { Icon } from '@chakra-ui/icons'
import { Circle, SquareProps } from '@chakra-ui/layout'

import { speciesIconMap } from '~config/constants'
import { PatientFieldsFragment, Species } from '~graphql/generated/graphql'

interface Props extends SquareProps {
    patient?: Pick<
        PatientFieldsFragment,
        'avatar_url' | 'clinic_species'
    > | null
}

export default function SpeciesIcon({ patient, ...props }: Props) {
    if (patient?.avatar_url) {
        return <Avatar src={patient.avatar_url} name={''} size="md" />
    }

    return (
        <Circle bg="brand.100" size={12} p={2} {...props}>
            <Icon
                as={
                    speciesIconMap[
                        patient?.clinic_species?.species || Species.Other
                    ]
                }
                color="brand.800"
                w="full"
                h="full"
            />
        </Circle>
    )
}
