import React from 'react'
import {
    BiCog,
    BiCopy,
    BiRefresh,
    BiInfoCircle,
    BiLinkExternal,
} from 'react-icons/bi'
import { navigate } from 'gatsby'
import { Image } from '@chakra-ui/image'
import { MenuGroup } from '@chakra-ui/menu'
import { Box, Text, Link, AspectRatio } from '@chakra-ui/layout'

import { useUserContext } from '~config/user-context'
import MenuItem from '~components/shared/menu/menu-item'
import { AdminRoutes, UserRoutes } from '~config/routes'
import {
    ClinicFieldsFragment,
    ClinicGroupFieldsFragment,
} from '~graphql/generated/graphql'
import SharedStack from '~components/shared/wrappers/shared-stack'
import { getSupabaseLink, SupabaseEntities } from '~config/constants'

interface Props {
    clinic: ClinicFieldsFragment
    setClinic: (e: ClinicFieldsFragment | undefined) => void
    setClinicGroup: (e: ClinicGroupFieldsFragment | undefined) => void
    isClinicAdmin: boolean
    canSwitch?: boolean
}

export default function ClinicOptions({
    clinic,
    setClinic,
    setClinicGroup,
    isClinicAdmin,
    canSwitch = true,
}: Props) {
    const { isAdmin } = useUserContext()

    return (
        <>
            <SharedStack row fit px={3} spacing={4} mb={2}>
                <AspectRatio
                    ratio={1}
                    w={12}
                    borderWidth={1}
                    rounded="md"
                    overflow="hidden"
                    borderColor="gray.200"
                >
                    <Image
                        src={clinic.avatar_url || ''}
                        alt={clinic.name || ''}
                    />
                </AspectRatio>
                <Box>
                    <Text fontSize="md" fontWeight="semibold">
                        {clinic.name}
                    </Text>
                    {isAdmin && (
                        <Text fontSize="xs" color="gray.500">
                            {clinic.id}
                        </Text>
                    )}
                </Box>
            </SharedStack>
            {(isClinicAdmin || isAdmin) && (
                <MenuItem
                    icon={BiInfoCircle}
                    onClick={() => navigate(UserRoutes.ClinicInfo)}
                >
                    View Info
                </MenuItem>
            )}
            {(canSwitch || isAdmin) && (
                <MenuItem
                    icon={BiRefresh}
                    onClick={() => {
                        setClinic(undefined)
                        setClinicGroup(undefined)
                        navigate(UserRoutes.SelectClinic)
                    }}
                >
                    Switch Clinic
                </MenuItem>
            )}
            {isAdmin && (
                <MenuGroup title="Admin" fontSize="xs">
                    <MenuItem
                        icon={BiCopy}
                        onClick={() => navigator.clipboard.writeText(clinic.id)}
                    >
                        Copy Clinic ID
                    </MenuItem>
                    <Link
                        variant="unstyled"
                        href={getSupabaseLink(
                            SupabaseEntities.Clinic,
                            clinic.id,
                        )}
                        isExternal
                    >
                        <MenuItem icon={BiLinkExternal}>
                            View in Supabase
                        </MenuItem>
                    </Link>
                    <Link
                        variant="unstyled"
                        href={AdminRoutes.EditClinic(clinic.id)}
                    >
                        <MenuItem icon={BiCog}>Admin Settings</MenuItem>
                    </Link>
                </MenuGroup>
            )}
        </>
    )
}
