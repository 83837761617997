import React, { useState, createContext, useEffect, useContext } from 'react'
import { LocalStorageKeys } from '~config/constants'
import { useUserContext } from '~config/user-context'

import {
    FilterOp,
    AuthRoles,
    useGetClinicQuery,
    ClinicFieldsFragment,
    useGetClinicAdminQuery,
    useCountOrganizationsQuery,
    useCountClinicsForUserAdminQuery,
    ClinicGroupFieldsFragment,
    useGetClinicGroupQuery,
    useCountClinicGroupsForUserQuery,
} from '~graphql/generated/graphql'
import { csArray } from '~utils/helpers'

type ClinicProviderType = {
    clinic: ClinicFieldsFragment | undefined
    hasMultipleClinics: boolean
    setClinic: (e: ClinicFieldsFragment | undefined) => void
    clinicGroup: ClinicGroupFieldsFragment | undefined
    setClinicGroup: (e: ClinicGroupFieldsFragment | undefined) => void
    isClinicAdmin: boolean
    refetchClinic: ({ id }: { id: string }) => Promise<any>
    clinicInStorage: boolean | undefined
    loading: boolean
}

export const ClinicContext = createContext<ClinicProviderType>(
    {} as ClinicProviderType,
)

export const ClinicProvider = ({ children }: { children: React.ReactNode }) => (
    <ClinicContext.Provider value={{ ...useClinicValues() }}>
        {children}
    </ClinicContext.Provider>
)

function useClinicValues(): ClinicProviderType {
    const { userId, user } = useUserContext()

    const [clinic, setClinic] = useState<ClinicFieldsFragment>()
    const [clinicGroup, setClinicGroup] = useState<ClinicGroupFieldsFragment>()

    const [isClinicAdmin, setIsClinicAdmin] = useState<boolean>(false)
    const [hasMultipleClinics, setHasMultipleClinics] = useState<boolean>(false)

    const [clinicInStorage, setClinicInStorage] = useState<string>()
    const [clinicGroupInStorage, setClinicGroupInStorage] = useState<string>()

    const handleSetClinic = (clinic: ClinicFieldsFragment | undefined) => {
        const organization = localStorage.getItem(
            LocalStorageKeys.selectedOrganization,
        )

        if (clinic) {
            localStorage.setItem(LocalStorageKeys.selectedClinic, clinic.id)
            if (organization)
                localStorage.removeItem(LocalStorageKeys.selectedOrganization)
        } else {
            localStorage.removeItem(LocalStorageKeys.selectedClinic)
        }
        setClinic(clinic)
    }

    const handleSetClinicGroup = (
        clinicGroup: ClinicGroupFieldsFragment | undefined,
    ) => {
        if (clinicGroup) {
            localStorage.setItem(
                LocalStorageKeys.selectedClinicGroup,
                clinicGroup.id,
            )
        } else {
            localStorage.removeItem(LocalStorageKeys.selectedClinicGroup)
        }
        setClinicGroup(clinicGroup)
    }

    const {
        data: clinicData,
        refetch,
        loading: clinicLoading,
    } = useGetClinicQuery({
        variables: {
            id: clinicInStorage || '',
        },
        onError: () => {
            setClinic(undefined)
            setClinicInStorage(undefined)
        },
        skip: !clinicInStorage || !user,
    })

    const { data: clinicAdminData } = useGetClinicAdminQuery({
        variables: {
            clinicId: clinic?.id || '',
            userId: userId || '',
        },
        skip: !clinic?.id || !user,
    })

    const {
        data: clinicGroupData,
        loading: clinicGroupLoading,
        refetch: refetchClinicGroup,
    } = useGetClinicGroupQuery({
        variables: {
            id: clinicGroupInStorage || '',
        },
        onError: () => {
            setClinicGroup(undefined)
            setClinicGroupInStorage(undefined)
        },
        skip: !clinicGroupInStorage || !user,
    })

    const { data: adminOrgsCount } = useCountOrganizationsQuery({
        variables: {
            where: [
                {
                    column: 'admins',
                    filterOp: FilterOp.Cs,
                    value: { string: csArray([user?.id || '']) },
                },
            ],
        },
    })

    const { data: adminClinicsCount } = useCountClinicsForUserAdminQuery({
        variables: {
            userId: userId || '',
        },
        skip: !userId,
    })

    const { data: adminClinicGroupsCount } = useCountClinicGroupsForUserQuery({
        variables: {
            userId: userId || '',
        },
        skip: !userId,
    })

    /* Clinic state flow START */
    // Check in storage for clinic when loaded
    useEffect(() => {
        const clinicInStorage = localStorage.getItem(
            LocalStorageKeys.selectedClinic,
        )
        setClinicInStorage(clinicInStorage || undefined)
    }, [])

    // If clinic data received, set clinic state
    useEffect(() => {
        setClinic(clinicData?.clinic as ClinicFieldsFragment)
    }, [clinicData])
    /* Clinic state flow END */

    /* Clinic group state flow START */
    // Check in storage for clinic group when loaded
    useEffect(() => {
        const clinicGroupInStorage = localStorage.getItem(
            LocalStorageKeys.selectedClinicGroup,
        )
        setClinicGroupInStorage(clinicGroupInStorage || undefined)
    }, [])

    // If clinic group data received, set clinic group state
    useEffect(() => {
        setClinicGroup(
            clinicGroupData?.clinicGroup as ClinicGroupFieldsFragment,
        )
    }, [clinicGroupData])
    /* Clinic group state flow END */

    // Check and set user role for selected clinic
    useEffect(() => {
        const role = clinicAdminData?.getClinicAdmin?.role
        setIsClinicAdmin(role ? role === AuthRoles.ClinicManager : false)
    }, [clinicAdminData])

    useEffect(() => {
        const userClinics = adminClinicsCount?.countClinicsForUserAdmin || 0
        const userOrgs = adminOrgsCount?.countOrganizations || 0
        const groupsCount =
            adminClinicGroupsCount?.countClinicGroupsForUser || 0
        setHasMultipleClinics(Boolean(userOrgs + userClinics + groupsCount > 1))
    }, [adminClinicsCount, adminOrgsCount, adminClinicGroupsCount])

    return {
        clinic,
        setClinic: handleSetClinic,
        hasMultipleClinics,
        clinicGroup,
        setClinicGroup: handleSetClinicGroup,
        isClinicAdmin,
        refetchClinic: refetch,
        clinicInStorage: Boolean(clinicInStorage),
        loading: clinicLoading || clinicGroupLoading,
    }
}

export const useClinic = () => {
    return useContext(ClinicContext)
}
