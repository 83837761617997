import React from 'react'
import { Text, Center } from '@chakra-ui/layout'
import type { CenterProps } from '@chakra-ui/layout'

import SharedHeader from './texts/shared-header'
import SharedInfoText from './texts/shared-info-text'

interface Props extends CenterProps {
    header?: string
    infoText?: string
}

export default function EmptyView({ header, infoText, ...props }: Props) {
    return (
        <Center
            bg="gray.50"
            rounded="xl"
            flexDir="column"
            w="full"
            h="full"
            gridGap={2}
            my={4}
            py={12}
            {...props}
        >
            <SharedHeader>{header || `There's nothing here`}</SharedHeader>
            <SharedInfoText>
                {infoText ||
                    `There were no results returned, but you can reload the page to
                try again.`}
            </SharedInfoText>
        </Center>
    )
}
