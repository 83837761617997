import React from 'react'
import { Icon } from '@chakra-ui/icon'
import { Avatar } from '@chakra-ui/avatar'
import { FaUser } from 'react-icons/fa'
import { Box, Flex, Text, HStack } from '@chakra-ui/layout'

import { AppointmentFieldsFragment } from '~graphql/generated/graphql'
import { getClientNameFromAppointment } from '~utils/appointment-helpers'
import GradientDivider from '~components/shared/gradient-divider'
import SharedStack from '~components/shared/wrappers/shared-stack'
import { formatEmail, formatPhoneNumber } from '~utils/helpers'
import InfoItem, { InfoText } from '~components/shared/info-item'
import NewTag from '~components/shared/tags/new-tag'
import AddressDisplay from '~components/shared/address-display'
import IconButtonWithTooltip from '~components/shared/icon-button-with-tooltip'
import { FiExternalLink } from 'react-icons/fi'
import { navigate } from 'gatsby'
import { getCleanedQueryParams } from '~utils/get-cleaned-query-params'
import { useClinic } from '~contexts/clinic-context'

interface Props {
    appointment: AppointmentFieldsFragment
}

const AppointmentClientSection: React.FC<Props> = ({ appointment }) => {
    const { clinic } = useClinic()
    const { client: clientId } = getCleanedQueryParams()
    const client = appointment.client

    return (
        <Box flex={1}>
            <Flex alignItems="center" justifyContent="space-between">
                <HStack spacing={4} py={1} px={2}>
                    <Icon as={FaUser} color="gray.900" />
                    <Text fontWeight="semibold">Client</Text>
                    {appointment.is_new_client && <NewTag />}
                </HStack>
                {!clientId && (
                    <IconButtonWithTooltip
                        label="View client details"
                        icon={<Icon as={FiExternalLink} />}
                        size="sm"
                        variant="subtle"
                        colorScheme="brand"
                        onClick={() => {
                            navigate(`?client=${client?.id}`)
                        }}
                    />
                )}
            </Flex>
            <GradientDivider my={2} />
            <SharedStack w="full" p={2} spacing={3}>
                <Flex
                    flexDir="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gridGap={4}
                    w="full"
                    pb={1}
                >
                    <Box flex={1}>
                        <Text fontWeight="bold">
                            {getClientNameFromAppointment(appointment)}
                        </Text>
                    </Box>
                    <Avatar
                        size="sm"
                        src={''}
                        name={getClientNameFromAppointment(appointment)}
                    />
                </Flex>
                <InfoItem
                    label="Phone"
                    info={
                        <Text fontSize="sm">
                            {formatPhoneNumber(
                                client?.phone,
                                client?.address?.country ||
                                    clinic?.address?.country,
                            )}
                        </Text>
                    }
                    isShorter
                />
                <InfoItem
                    label="Email"
                    info={
                        <Text fontSize="sm" wordBreak={'break-all'}>
                            {formatEmail(client?.email)}
                        </Text>
                    }
                    isShorter
                />
                <InfoItem
                    label="Address"
                    info={
                        <InfoText>
                            {client?.address ? (
                                <AddressDisplay address={client?.address} />
                            ) : null}
                        </InfoText>
                    }
                    isShorter
                />
            </SharedStack>
        </Box>
    )
}

export default AppointmentClientSection
